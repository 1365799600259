var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dn-settings dn-container" },
    [
      _c("back-button", {
        on: {
          back: function ($event) {
            return _vm.$router.push({ name: "home" })
          },
        },
      }),
      _c("h1", { staticClass: "dn-container__title" }, [_vm._v("Settings")]),
      _c("div", { staticClass: "dn-settings__profile-section" }, [
        _c("h2", [_vm._v("My Profile")]),
        _c("div", { staticClass: "dn-settings__profile" }, [
          _c("div", { staticClass: "dn-settings__profile__avatar" }, [
            _vm.user.profile.avatarUrl
              ? _c(
                  "div",
                  [
                    _c(
                      "v-avatar",
                      { attrs: { size: "80" } },
                      [
                        _c("v-img", {
                          attrs: { src: _vm.user.profile.avatarUrl },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _c("div", { staticClass: "dn-settings__profile__meta" }, [
              _c("p", { staticClass: "dn-settings__profile__meta__name" }, [
                _vm._v(" " + _vm._s(_vm.user.profile.name) + " "),
              ]),
              _c("p", { staticClass: "dn-settings__profile__meta__tier" }, [
                _vm._v(" " + _vm._s(_vm.user.profile.tier.name) + " "),
              ]),
            ]),
          ]),
          _vm._m(0),
        ]),
        _c("ul", { staticClass: "dn-settings__profile__list" }, [
          _c("li", [
            _c("p", [
              _vm._v(" Email: "),
              _c("strong", [_vm._v(_vm._s(_vm.user.profile.email))]),
            ]),
          ]),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "dn-settings__profile-section" },
        [
          _c(
            "v-btn",
            {
              attrs: { color: "primary", text: "", outlined: "" },
              on: {
                click: function ($event) {
                  _vm.editProfile = true
                },
              },
            },
            [_vm._v("Edit Profile")]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "dn-settings__profile-section" },
        [
          _c(
            "v-btn",
            {
              attrs: { color: "primary", text: "", outlined: "" },
              on: {
                click: function ($event) {
                  _vm.updatePassword = true
                },
              },
            },
            [_vm._v("Update Password")]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "dn-settings__profile-section" },
        [
          _vm.loading
            ? _c("v-progress-circular", { attrs: { indeterminate: "" } })
            : _c(
                "v-btn",
                {
                  attrs: { color: "primary", text: "", outlined: "" },
                  on: { click: _vm.handleUpdateSubscription },
                },
                [_vm._v("Update Subscription")]
              ),
        ],
        1
      ),
      _c("profile-modal", {
        attrs: { show: _vm.editProfile },
        on: {
          toggle: function ($event) {
            _vm.editProfile = !_vm.editProfile
          },
        },
      }),
      _c("password-modal", {
        attrs: { show: _vm.updatePassword },
        on: {
          toggle: function ($event) {
            _vm.updatePassword = !_vm.updatePassword
          },
        },
      }),
      _vm.showUpgradeButton
        ? _c(
            "v-btn",
            {
              staticClass: "mt-4",
              attrs: { outlined: "", color: "white", to: "/tiers" },
            },
            [_vm._v("Upgrade to Core")]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "dn-settings__profile__date" }, [
      _c("p", [_vm._v("Member Since")]),
      _c("p", [_vm._v("2020")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }