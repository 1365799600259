var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      staticClass: "dn-profile-modal",
      attrs: {
        overlay: true,
        "max-width": "500px",
        transition: "dialog-transition",
      },
      on: { input: _vm.handleClose },
      model: {
        value: _vm.showModal,
        callback: function ($$v) {
          _vm.showModal = $$v
        },
        expression: "showModal",
      },
    },
    [
      _c(
        "v-card",
        { staticClass: "dn-profile-modal__card" },
        [
          _c("v-card-title", { attrs: { "primary-title": "" } }, [
            _vm._v(" Edit Profile "),
          ]),
          _c(
            "v-card-text",
            [
              _c(
                "v-form",
                [
                  _c(
                    "v-list-tile",
                    { attrs: { avatar: "" } },
                    [
                      _c("v-file-input", {
                        attrs: {
                          accept: "image/png, image/jpeg, image/bmp",
                          placeholder: "Upload Avatar",
                          "prepend-icon": "mdi-camera",
                          label: "Avatar",
                        },
                        model: {
                          value: _vm.avatar,
                          callback: function ($$v) {
                            _vm.avatar = $$v
                          },
                          expression: "avatar",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("v-text-field", {
                    attrs: {
                      name: "email",
                      label: "Email",
                      type: "email",
                      disabled: "",
                      maxlength: 25,
                    },
                    model: {
                      value: _vm.profile.email,
                      callback: function ($$v) {
                        _vm.$set(_vm.profile, "email", $$v)
                      },
                      expression: "profile.email",
                    },
                  }),
                  _c("v-text-field", {
                    attrs: { name: "email", label: "Full Name", maxlength: 25 },
                    model: {
                      value: _vm.profile.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.profile, "name", $$v)
                      },
                      expression: "profile.name",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-card-actions",
            { staticClass: "pa-6" },
            [
              _c(
                "v-btn",
                {
                  attrs: { text: "", color: "grey" },
                  on: { click: _vm.handleClose },
                },
                [_vm._v("Cancel")]
              ),
              _c("v-spacer"),
              _vm.saving
                ? _c("v-progress-circular", { attrs: { indeterminate: "" } })
                : _c(
                    "v-btn",
                    {
                      attrs: { text: "", color: "primary" },
                      on: { click: _vm.handleSave },
                    },
                    [_vm._v("Save")]
                  ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }