var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      staticClass: "dn-password-modal",
      attrs: {
        overlay: true,
        "max-width": "500px",
        transition: "dialog-transition",
      },
      on: { input: _vm.handleClose },
      model: {
        value: _vm.showModal,
        callback: function ($$v) {
          _vm.showModal = $$v
        },
        expression: "showModal",
      },
    },
    [
      _c(
        "v-card",
        { staticClass: "dn-password-modal__card" },
        [
          _c("v-card-title", { attrs: { "primary-title": "" } }, [
            _vm._v(" Update Password "),
          ]),
          _c(
            "v-card-text",
            [
              _c(
                "v-form",
                [
                  _c("v-text-field", {
                    attrs: {
                      name: "newPassword",
                      label: "New Password",
                      id: "new-password",
                      type: "password",
                    },
                    model: {
                      value: _vm.password,
                      callback: function ($$v) {
                        _vm.password = $$v
                      },
                      expression: "password",
                    },
                  }),
                  _c("v-text-field", {
                    attrs: {
                      name: "confirmNewPassword",
                      label: "Confirm New Password",
                      id: "confirm-password",
                      type: "password",
                    },
                    model: {
                      value: _vm.confirmPassword,
                      callback: function ($$v) {
                        _vm.confirmPassword = $$v
                      },
                      expression: "confirmPassword",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-card-actions",
            { staticClass: "pa-6" },
            [
              _c(
                "v-btn",
                {
                  attrs: { color: "grey", text: "" },
                  on: { click: _vm.handleClose },
                },
                [_vm._v("cancel")]
              ),
              _c("v-spacer"),
              _vm.loading
                ? _c("v-progress-circular", { attrs: { indeterminate: "" } })
                : _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "primary",
                        text: "",
                        disabled: _vm.disableSave,
                      },
                      on: { click: _vm.handleSave },
                    },
                    [_vm._v("Update Password")]
                  ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }